import { combineReducers } from '@reduxjs/toolkit'
import AccountSelectorReducer from '../accountselector/store/AccountSelectorSlice'
import StaticConfigReducer from './StaticConfigSlice'
import PreferenceReducer from './PreferencesSlice'
import TotalNetWorthReducer from '../accountselector/api/TotalNetWorthSlice'
import RcmTabMenuReducer from '../rcmtabmenu/RcmTabMenuSlice'
import QuickLinksReducer from '../quicklinks/store/QuickLinkSlice'
import AssetAllocationReducer from '../assetallocation/store/AssetAllocationSlice'
import RcmNavbarReducer from '../rcmnavbar/store/RcmNavbarSlice'
import CobDataReducer from '../cobtoggle/store/CobDataSlice'

const sharedReducer = combineReducers({
  staticConfig: StaticConfigReducer,
  accountSelector: AccountSelectorReducer,
  rcmTabMenu: RcmTabMenuReducer,
  rcmNavbar: RcmNavbarReducer,
  preferences: PreferenceReducer,
  totalNetWorth: TotalNetWorthReducer,
  quickLinks: QuickLinksReducer,
  assetAllocation: AssetAllocationReducer,
  cobData: CobDataReducer
})

export default sharedReducer
