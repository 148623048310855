import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'

interface INavbarStateState {
  snapQuoteIdentifier?: string | null
}

const initialState: INavbarStateState = {}

const Slice = createSlice({
  name: '@shared/rcmnavbar',
  initialState,
  reducers: {
    SetSnapQuoteIdentifierState: (state, action: PayloadAction<string | undefined | null>) => {
      return { ...state, snapQuoteIdentifier: action.payload }
    }
  }
})

export const { SetSnapQuoteIdentifierState } = Slice.actions
export const getSnapQuoteIdentifier = (state: RootState) => state.shared.rcmNavbar.snapQuoteIdentifier
export default Slice.reducer
