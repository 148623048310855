export const getJsonFromUrl = (url?: string) => {
  if (!url) url = window.location.search
  const query = url.substring(1)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [props: string]: any } = new Object()
  query.split('&').forEach(function (part) {
    const item = part.split('=')
    if (item[0]) {
      result[item[0] as keyof typeof result] = decodeURIComponent(item[1])
    }
  })
  return isEmptyObject(result) ? undefined : result
}

export const getQueryStringFromObject = (payload: object) => {
  const formatObject = Object.keys(payload)
    .filter((key) => payload[key as keyof typeof payload] !== undefined)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(payload[key as keyof typeof payload] as string | number)}`
    )
    .join('&')
  const queryString = formatObject ? `?${formatObject}` : ''
  return queryString
}

const isEmptyObject = (obj: object) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false
    }
  }
  return true
}
