import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'
import {
  MarketNewsArticle,
  MarketNewsArticlesApiResponse
} from '../components/articles/api/types/MarketNewsArticles.type'

interface INewsSliceState {
  newsArticles: MarketNewsArticle[]
  nextPageKey: string
}

const initialState: INewsSliceState = {
  newsArticles: [],
  nextPageKey: ''
}

const Slice = createSlice({
  name: '@module/Market-News',
  initialState,
  reducers: {
    SetNewsArticles: (state, action: PayloadAction<MarketNewsArticlesApiResponse>) => {
      return {
        ...state,
        newsArticles: [...state.newsArticles, ...(action.payload.response?.data ?? [])],
        nextPageKey: action.payload.response?.meta?.pagination?.next ?? ''
      }
    },
    ClearNewsArticles: (state) => {
      return {
        ...state,
        ...initialState
      }
    }
  }
})

export const { SetNewsArticles, ClearNewsArticles } = Slice.actions
export const getNewsArticles = (state: RootState) => state.modules.markets.news.newsArticles
export const getNewsNextPageKey = (state: RootState) => state.modules.markets.news.nextPageKey
export default Slice.reducer
