import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'
import { rockefeller } from '../system/environment/Config.json'
import { MsalToken } from '../system/msal/MsalToken'

const fetchBaseRockefellerNonContextApiQuery = fetchBaseQuery({
  baseUrl: rockefeller.api.baseurl,
  jsonContentType: 'application/json; charset=UTF-8;',
  prepareHeaders: async (headers: Headers, api) => {
    const tokenResult = await MsalToken.getAccessToken([rockefeller.api.scope])
    const state = api.getState() as RootState
    headers.append('Authorization', `${tokenResult.tokenType} ${tokenResult.accessToken}`)
    headers.append('cache-control', 'no-cache')
    headers.append('profilejson', state.app.profileKey ?? '')
    headers.append('contextjson', state.app.contextKey ?? '')
    if (rockefeller.api.version) {
      headers.append('version', rockefeller.api.version)
    }
    return headers
  }
})

const fetchBaseRockefellerNonContextApiQueryWithRetry = retry(fetchBaseRockefellerNonContextApiQuery, {
  maxRetries: 0
})

const RockefellerNonContextApiSlice = createApi({
  reducerPath: '@api/rockefellernoncontext',
  baseQuery: fetchBaseRockefellerNonContextApiQueryWithRetry,
  endpoints: () => ({}),
  keepUnusedDataFor: 60 * 60,
  tagTypes: [
    'ExternalAccountList',
    'PendingTransfers',
    'EligiblePairs',
    'ManualAssetList',
    'AllAccountBalances',
    'OtherAssets',
    'Preferences',
    'Refresh',
    'ExternalAccountUserConnections',
    'WatchList',
    'WatchListItems',
    'SummaryWidgetOrder'
  ],
  invalidationBehavior: 'immediately'
})

export default RockefellerNonContextApiSlice
