import { combineReducers } from '@reduxjs/toolkit'
import LoginReducer from '../login/store/LoginSlice'
import HomeReducer from '../home/store/HomeSlice'
import SummaryReducer from '../summary/store/SummarySlice'
import AccountsReducer from '../accounts/store/AccountsReducers'
import PaymentsReducer from '../payments/store/PaymentsSlice'
import CollaborationsReducer from '../collaboration/store/CollaborationSlice'
import MyProfileReducer from '../myprofile/store/MyProfileSlice'
import StrategicInsightsReducer from '../strategicinsights/store/StrategicInsightsSlice'
import RegistrationReducer from '../registration/store/RegistrationSlice'
import MarketsReducer from '../markets/store/MarketsReducers'
export const modulesReducer = combineReducers({
  home: HomeReducer,
  login: LoginReducer,
  summary: SummaryReducer,
  accounts: AccountsReducer,
  payments: PaymentsReducer,
  collaborations: CollaborationsReducer,
  myProfile: MyProfileReducer,
  markets: MarketsReducer,
  strategicInsights: StrategicInsightsReducer,
  registration: RegistrationReducer
})
