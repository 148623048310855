import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'

interface ICobDataSliceState {
  cobToggleValue: boolean
}

const initialState: ICobDataSliceState = {
  cobToggleValue: false
}

const Slice = createSlice({
  name: '@shared/codData',
  initialState,
  reducers: {
    SetCobToggleValue: (state, action: PayloadAction<boolean>) => {
      return { ...state, cobToggleValue: action.payload }
    }
  }
})

export const { SetCobToggleValue } = Slice.actions
export const getCobToggleValue = (state: RootState) => state.shared.cobData.cobToggleValue ?? false
export default Slice.reducer
